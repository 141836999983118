<template>
    <component :is="tag || 'div'" :class="classes"  :style="style" v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
        <slot></slot>
    </component>
</template>

<style>
.draggable {
    display: inline-block;
}
</style>


<script>
import _ from "lodash";
export default {
    name: "Draggable",
    props: ["dragData", "dragGroup", "dragType", "as", "selected", "classes"],
    data() {
        return {
            dragging: false,
            tag: this.as || "div"
        };
    },
    computed: {
        style() {
            if (this.dragging)
                return {
                    zIndex: 100,
                    position: "relative",
                    cursor: "grabbing",
                };
            else {
                return {
                    cursor: "grab",
                };
            }
        }
    },
    methods: {
        startDragging(mouseX, mouseY) {
            let mouseUpListener = e => {
                try {
                    if (this.dragging) {
                        //get all elements on the dom under our mouse
                        let targets = document.elementsFromPoint(e.clientX, e.clientY);

                        //filter elements to elements that include our drag-target attr
                        targets = _.filter(targets, el => el.attributes["drag-target"]);

                        targets.forEach(target => {
                            let vm = target.__vue__;

                            //If we use dynamic vue components as our DragTarget type (ie we set :as="VueComponent"),
                            //sometimes we get weird behavoir where the actual target ends up
                            //being the parent of the component that our data is on.
                            //So check for this here, if our target doesn't have our dragtarget option on it,
                            //get the parent and use that instead.
                            //This doesn't happen if we use html elements (ie div, span)
                            vm = vm.$options.dragtarget ? vm : vm.$parent;

                            let accepts = vm.$props["dropAccepts"];
                            let dropData = vm.$props["dropData"];
                            let dropGroup = vm.$props["dropGroup"];

                            //check if our target accepts our draggable
                            if (this.$dragEventBus.checkAccepts(this.dragType, accepts, this.dragGroup, dropGroup)) {
                                //emit to drop-target as "trigger-dropped" (bubbles up as "dropped") event
                                vm.$emit("trigger-dropped", this.dragData, dropData, this.dragGroup, dropGroup);

                                //emit to draggable as "pulled" event
                                this.$emit("pulled", this.dragData, dropData, this.dragGroup, dropGroup);
                                this.$dragEventBus.$emit("drag-stop");
                            }
                        });
                    }
                } catch (e) {
                    console.log(e);
                 }
                //clear drag state
                this.dragging = false;
                let transition = el.style.transition;
                el.style.transition = "all 0.2s ease"
                el.style.transform = ``;

                setTimeout(() => el.style.transition = transition, 200)

                window.removeEventListener("mousemove", moveListener);
                window.removeEventListener("mouseup", mouseUpListener);

                this.$dragEventBus.$emit("drag-stop");
                this.$emit("drag-stop");
            };

            let el = this.$el;

            let moveListener = e => {
                this.dragTick++;
                if (this.dragging) {
                    el.style.transform = `translate(${e.clientX - mouseX}px, ${e.clientY - mouseY}px)`;
                }
                else if (!this.dragging && (Math.abs(e.clientX - mouseX) > 5 || Math.abs(e.clientY - mouseY) > 5)) {
                    this.dragging = true;
                    el.style.transition = "all 0s ease"

                    this.$dragEventBus.$emit("drag-start", this.dragData, this.dragType, this.dragGroup, e.clientX, e.clientY);
                    this.$emit("drag-start", this.dragData, this.dragType, this.dragGroup, e.clientX, e.clientY);
                }
            };
            window.addEventListener("mouseup", mouseUpListener);
            window.addEventListener("mousemove", moveListener);
        },
        dragStartEvent(data, type, group, x, y) {
            if (this.selected && this.dragGroup == group) {
                this.startDragging(x, y);
            }
        }
    },
    mounted() {
        this.$el.addEventListener("mousedown", e => {
            e.preventDefault();
            e.stopPropagation();

            this.startDragging(e.clientX, e.clientY);
        });
        this.$dragEventBus.$on("drag-start", this.dragStartEvent);
    },
    destroyed() {
        this.$dragEventBus.$off("drag-start", this.dragStartEvent);
    }
};
</script>

<template>
    <div>
        <div class="credit">Created by <a target="_blank" href="https://twitter.com/Double0_">@Double0negative</a> 
            <!-- | <a href="" @click="toggleSnowflakes">Toggle Snow</a>  -->
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        toggleSnowflakes() {
            let r = localStorage.getItem("disable-snowflakes") != 'false';
            console.log( localStorage.getItem("disable-snowflakes"), r);
            localStorage.setItem("disable-snowflakes", !r);
        }
    }
}
</script>
<style>
.credit {
    margin: 5px auto;
    text-align: center;
}
</style>
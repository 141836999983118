
import Draggable from "./components/draggable"
import DragTarget from "./components/drag-target"
import _ from "lodash"

export default {
    install(Vue) {
        let dragEventBus = new Vue({
            data: {

            },
            methods: {
                checkAccepts(type, accepts, dragGroup, dropGroup) {
                    let groupAccepts = true;
                    if (dragGroup && dropGroup) {
                        groupAccepts = dragGroup != dropGroup
                    }
                    return groupAccepts && (type == undefined ||
                        accepts == undefined ||
                        (_.isArray(accepts) && _.includes(accepts, type)) ||
                        (type == accepts))
                }
            }
        });

        Vue.prototype.$dragEventBus = dragEventBus;

        Vue.component("drag-target", DragTarget)
        Vue.component("draggable", Draggable)
    }
}
<template>
    <component class="drag--drag-target" drag-target="drag-target" v-bind="{...$props, ...$attrs}" :class="classes" :is="tag">
        <div class="drag--overlay-wrap" v-if="showOverlay">
            <slot name="overlay">
                <div class="drag--overlay">
                    <div class="drag--overlay-inner">{{dropText ?? 'Drop Here'}}</div>
                </div>
            </slot>
        </div>
        <slot></slot>
    </component>
</template>
<style>
.drag--drag-target {
    position: relative;
}

.drag--overlay-wrap {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.drag--overlay-wrap>div {
    background: rgba(0, 0, 100, 0.3);
    border: 5px dashed blue;
    border-radius: 20px !important;
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.drag--overlay-wrap>div>div,
.drag--overlay-inner {
    font-size: 2em;
    text-shadow: 0 0 10px #fff;
}
</style>
<script>
export default {
    name: "drag-target",
    dragtarget: true,
    props: ["dropAccepts", "dropData", "dropGroup", "as", "classes", "dropText"],
    data() {
        return {
            tag: this.as || "div",
            showOverlay: false
        };
    },
    methods: {
        triggerDropped(dragData, dropData, dragGroup, dropGroup) {
            this.$emit("dropped", dragData, dropData, dragGroup, dropGroup);
        },
        dragStart(data, type, dragGroup) {
            if (this.$dragEventBus.checkAccepts(type, this.dropAccepts, dragGroup, this.dropGroup)) {
                this.showOverlay = true;
            }
        },
        dragStop() {
            this.showOverlay = false;
        }
    },
    created() {
        this.$on("trigger-dropped", this.triggerDropped);
        this.$dragEventBus.$on("drag-start", this.dragStart);
        this.$dragEventBus.$on("drag-stop", this.dragStop);
    },
    destroyed() {
        this.$off("trigger-dropped", this.triggerDropped);
        this.$dragEventBus.$off("drag-start", this.dragStart);
        this.$dragEventBus.$off("drag-stop", this.dragStop);
    }
};
</script>
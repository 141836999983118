<template>
    <div class="options-container">
        <div class="options-wrap">
            <div v-for="option in options" :key="option.key" class="option" :class="{ selected: value == option.key, small }"
                @click="select(option)">
                {{ option.text }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["options", "value", "small"],
    methods: {
        select(option) {
            this.selected = option;
            this.$emit("input", option.key);
        }
    }
}
</script>
<style lang="scss" scoped>
.options-wrap {
    display: inline-block;
    margin: auto;
    border: 1px solid #fff7;
    border-radius: 10px;
    overflow: hidden;
}

.option {
    width: 150px;
    height: 60px;
    line-height: 60px;
    font-size: 1.3em;
    border-left: 1px solid #fffa;
    display: inline-block;
    text-align: center;
    cursor: pointer;

    &.noborder {
        border: none;
    }

    &.selected {
        background: $primary;
    }

    &.small {
        width: 60px;
        height: 40px;
        line-height: 40px;
        font-size: 1em;
    }
}
</style>
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                // primary: "#970b0b",
                primary: "#740d9d",
                secondary: "#261f1f",
                accent: "#fff",
                // primary: "#0b4a97",
                // secondary: "#261f1f",
                // accent: "#fff",
                // primary: "#cd1461"
            }
        }
    },
    icons: {
        iconfont: 'fa',
    },
});

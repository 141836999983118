<template>
    <div class="nav-container">
        <header class="main-nav elevation-0">
            <div class="toolbar-link-container">
                <router-link class="toolbar-link" :to="{ name: 'index', params: $props }"><img height="25"
                        src="/img/overstat_logo_100.webp" alt="logo" aria-label="Home"></router-link>
                <router-link class="toolbar-link text" :to="{ name: 'players', params: $props }" aria-label="search">Search</router-link>
                <router-link class="toolbar-link text" :to="{ name: 'map', params: $props }" aria-label="maps">Maps</router-link>
                <!-- <router-link class="toolbar-link text" :to="{ name: 'team-finder', params: $props }" aria-label="lft">LFT</router-link> -->
                <router-link class="toolbar-link text" :to="{ name: 'analytics', params: $props }" aria-label="analytics">Analytics</router-link>
            </div>
            <div class="toolbar-right-container">

                <router-link class="toolbar-link text" :to="{ name: 'admin', params: $props }">Organizer</router-link>
                <v-menu bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <a class="toolbar-link text" dark text v-bind="attrs" v-on="on">
                            Player
                        </a>
                    </template>

                    <div class="nav-dropdown">
                        <template v-if="username">
                            <router-link v-if="username" class="toolbar-link nav-dropdown-item"
                                    :to="{ name: 'account', params: $props }">Account</router-link>
                            <router-link v-if="username" class="toolbar-link nav-dropdown-item"
                                        :to="{ name: 'account.teams', params: $props }">Teams</router-link>
                            <router-link v-if="username" class="toolbar-link nav-dropdown-item"
                                            :to="{ name: 'account.messages', params: $props }">Messages</router-link>
                            <v-divider></v-divider>
                            <a class="toolbar-link  nav-dropdown-item" @click="logout">Logout</a>
                        </template>
                        <template v-if="!username">
                            <router-link class="toolbar-link nav-dropdown-item" :to="{ name: 'login', params: $props }">
                            Login
                            </router-link>
                            <router-link class="toolbar-link nav-dropdown-item" :to="{ name: 'create-account', params: $props }">
                                Sign Up
                            </router-link>
                        </template>
                    </div>
                </v-menu>

            </div>
        </header>
    </div>
</template>
<script>
export default {
    data() {
        return {
            update: Date.now(),
        }
    },
    computed: {
        username() {
            const user = this.$apex.getUser();
            if (user) {
                user.update = this.update;
                return user.username;
            } else {
                return undefined;
            }
        }
    },
    methods: {
        logout() {
            this.$apex.userLogout();
            this.$router.push({ name: "index" });
        }
    }
}
</script>
<style lang="scss" scoped>
.toolbar-link-container,
.toolbar-right-container,
.container {
    display: inline-block;
    height: 100%;
    vertical-align: baseline;
    display: flex;
}

.nav-container {
    background: $background;
    border-bottom: 3px solid $primary;
}

.main-nav {
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    height: 60px;
    box-shadow: 0 2px 30px #0004;
}

.main-nav a {
    text-decoration: none;
}

.toolbar-link-container {
    flex: 1;
}

.toolbar-link.text {
    padding-top: 2px;
}

@media screen and (max-width: 699px)   {
    .toolbar-link {
        padding-left: 4px;
        padding-right: 4px;
        color: white !important;
        text-decoration: none;
        align-self: end;
        margin-bottom: -4px;
        height: 80%;
    }
}

@media screen and (min-width: 700px) {
    .toolbar-link {
        padding-left: 20px;
        padding-right: 20px;
        color: white !important;
        text-decoration: none;
        align-self: end;
        margin-bottom: -4px;
        height: 80%;
    }
}



.nav-dropdown {
    font-family: "Roboto";
    background: $background;
    padding: 5px 0;
}

.nav-dropdown-item {
    padding: 10px 25px;
    display: block;
}

.v-menu__content {
    box-shadow: 0;
}

.highlight {
    text-shadow: 0 0 15px #fff;
    animation: highlight 3s linear infinite;
}

@keyframes highlight {
    0% { text-shadow: 0 0 0 #fff}
    50% { text-shadow: 0 0 15px #fff,  0 0 25px #fff }
    100% { text-shadow: 0 0 0 #fff }
}
</style>

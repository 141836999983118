<template>
    <div class="app-wrap">
        <template v-if="show">
            <div class="app-wrap-overlay"></div>
            <div class="app-wrap-overlay1"></div>

            <!-- <div id="canvas"></div> -->

        </template>
        <router-view class="main-wrap" />

    </div>
</template>

<script>

export default {
    name: 'App',
    data() {
        return {
            flakes: [],
            opacity: 1,
        }
    },
    computed: {
        show() {
            return !this.$route.name?.includes("broadcast") && !this.$route.name?.includes("liveboard")
        },
        disableSnowflakes() {
            return true; //localStorage.getItem("disable-snowflakes") === 'false'
        },
        name() {
            return this.$route.name;
        }
    },
    async mounted() {


        // setTimeout(() => this.opacity = 0, 1000);
    }
};
</script>

<style lang="scss">
#canvas {
    position: fixed;
    top: 0;
    bottom: 0;
    // width: 100%;
    // height: 100%;
}

.v-data-table tr:nth-child(odd) {
    background: $second-tone;
}

.v-data-table tr:nth-child(even) {
    background: $first-tone;
}

.theme--dark.v-toolbar.v-sheet {
    background: $first-tone !important;
}

.primary-background {
    background: $primary;
}

.first-tone-background {
    background-color: $first-tone;
}

.second-tone-background {
    background-color: $second-tone;
}

.third-tone-background {
    background-color: $third-tone;
}

.title-toolbar {
    height: 64px;
    background-color: $first-tone;
    font-size: 1.1em;
    line-height: 64px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 30px;
}

.list-item {
    min-height: 48px;
    background-color: $second-tone;
    border-radius: 5px;
    font-size: 1.0em;
    line-height: 48px;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 30px;
}

.page-nav {
    background-color: transparent;
    height: 60px;
}

.page-wrap {
    max-width: 1200px;
    margin: auto;
}

.content-wrap {
    background: $background;
    box-shadow: 0 0 30px #00000030;
    border-radius: 5px;
    border-top-left-radius: 0;
    backdrop-filter: blur(8px);

    &.full-wrap {
        border-top-left-radius: 5px;
    }
}

.header {
    font-size: 1.5em;
    text-transform: uppercase;
}

.content-box {
    background: $background-content;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;

    &.header {
        border-bottom: 3px solid $primary;
        border-radius: 0;
    }
}

.square {
    border-radius: 0 !important;
}

.content-box-header {
    background: $background-content;
    border-radius: 5px;
    padding: 10px;
    margin: 5px 0;
    border-bottom: 3px solid $primary;
    border-radius: 0;

 
}

.background-important {
    background: $background !important;
}

.link-container {
    display: inline-block;
    margin: auto;
    height: 100%;
    vertical-align: baseline;
    display: flex;
}

.nav-link {
    padding: 0 40px;
    color: white !important;
    text-decoration: none;
    align-self: end;
    line-height: 60px;
    height: 60px;
    text-shadow: 0 0 3px #000;
}

.page-nav .router-link-active {
    background: $background;
    box-shadow: 0 -9px 20px #00000060;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    // height: 70px;
    // margin-bottom: -10px;
}

.pag-wrap {
    max-width: 60%;
    margin: auto;
}

.subnav {
    height: 55px;
    display: flex;
    align-items: end;
    justify-content: center;
    padding: 0 30px;
    // background: #ffffff50;
}

@media screen and (max-width: 450px) {
    .subnav-link {
        padding: 0 10px;
        color: white !important;
        text-decoration: none;
        padding-bottom: 10px;
        white-space: nowrap;
    }
}

@media screen and (min-width: 450px) {
    .subnav-link {
        padding: 0 20px;
        color: white !important;
        text-decoration: none;
        padding-bottom: 10px;
        white-space: nowrap;
    }
}

.subnav-link.router-link-active {
    border-bottom: 2px solid $primary;
    padding-bottom: 8px;
}

.page-header {
    height: 80px;
    padding-top: 30px;
    color: white;
    // background: $third-tone;
    text-transform: capitalize;
    // text-shadow: 0 0 30px #000;
}

.page-header {
    display: flex;
}

.page-header .page-title {
    width: 100%;
    align-self: center;
    text-align: center;
    font-size: 2em !important;
}

.page-sub-title {
    font-size: .75em !important;
}

body {
    //   background: black;
}

.app-wrap {
    width: 100%;
    background: black;
    // background: linear-gradient(180deg, #880f0f, #5a1b1b 1000px);

    // background: linear-gradient(180deg, #2f6ca9, #8faac5 1000px);
    // position: absolute;
}

.app-wrap-overlay {
    // transition: all 100s;
    position: fixed;
    top: 0;
    left: 0;
    // background: linear-gradient(0deg, #245382, #000 1000px);
    // background-image: url("/public/img/we_bg.jpg");
    // background-image: url("/public/img/we_thermal_bg.webp");
    // background-image: url("/public/img/sp_s19_bg.jpg");
    background-image: url("/public/img/district_bg.webp");

    background-size: cover;
    height: 100%;
    width: 100%;
    opacity: .8;
}

// normal
// .app-wrap-overlay1 {
//     // transition: all 100s;
//     position: fixed;
//     top: 0;
//     // left: 0;
// background: linear-gradient(180deg, #a92f2f, #371212 1000px);
//     //     background: linear-gradient(180deg, #2f6ca9, #122437 1000px);

//     // background-image: url("/public/img/dark_bg.png");
//     background-repeat: repeat;
//     // background: linear-gradient(180deg, #360707, #420e0eaa 1000px);
//     // background-color: black;
//     height: 100%;
//     width: 100%;
//     opacity: .6;
// }

.app-wrap-overlay1 {
    // transition: all 100s;
    position: fixed;
    top: 0;
    // left: 0;
    // background: linear-gradient(180deg, #581414, #1b0606 1000px);
    // background: linear-gradient(180deg, #2f6ca9, #122437 1000px);
    background: linear-gradient(180deg, #300640, #0a010d 1000px);
    // background-image: url("/public/img/dark_bg.png");
    background-repeat: repeat;
    // background: linear-gradient(180deg, #360707, #420e0eaa 1000px);
    // background-color: black;
    height: 100%;
    width: 100%;
    opacity: .5;
}

.page-header {
    max-width: 1200px;
    margin: auto;
    // text-shadow: 0 0 50px #000, 0 0 50px #000, 0 0 50px #000, 0 0 50px #000 , 0 0 50px #000;

    .page-title {
        text-align: center;
    }
}




// #app:before {
//   content: ' ';
//   display: block;
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   opacity: 0.7;
//   // background-image: url("/public/img/sp_s19_bg.jpg");
// //   background: $page-bg;
//   background-repeat: no-repeat;
//   background-position: top center;
//   background-attachment: fixed;
//   background-size: 100%;
// }

#app {
    //   background:black;
}

.main-wrap.v-application a {
    color: $primary-link;
}

.theme--dark.v-application {
    background-color: transparent !important;
}

.table-wrap {
    margin: auto;
    background-color: $third-tone;
    border-radius: 10px;
    height: 100%;
    padding: 5px;
    margin: 4px;

    table {
        margin: auto;

        td {
            padding: 1px 5px;
            width: 150px;
        }

        .key {
            text-align: right;
            text-transform: capitalize;
        }

        .value {
            font-weight: 100;

        }
    }
}

</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import apex from "./plugins/apex";
import config from "./config/config.json";
import DragDrop from "./plugins/drag-drop";
import IconSpan from "./components/IconSpan";
import IconBtnFilled from "./components/IconBtnFilled";
import IconBtnOutline from "./components/IconBtnOutline";
import Footer from "./components/OFooter";
import NavBar from "./components/NavBar";
import Pagnation from "./components/Pagnation";
import SubscriptionWrap from "./components/SubscriptionWrap";
import Options from "./components/Options.vue";
import PlayerLink from "./components/PlayerLink.vue";
Vue.config.productionTip = false

Vue.use(apex, config.api);
Vue.use(DragDrop)

Vue.component("icon-span", IconSpan);
Vue.component("icon-btn-filled", IconBtnFilled);
Vue.component("icon-btn-outline", IconBtnOutline);
Vue.component("o-footer", Footer);
Vue.component("NavBar", NavBar);
Vue.component("Pagnation", Pagnation);
Vue.component("SubscriptionWrap", SubscriptionWrap);
Vue.component("Options", Options);
Vue.component("PlayerLink", PlayerLink);

const updatePageTitle = function (title) {
    document.title = title + ' - Overstat.gg';
};

Vue.directive('title', {
  inserted: (el, binding) => updatePageTitle(binding.value || el.innerText),
  update: (el, binding) => updatePageTitle(binding.value || el.innerText),
  componentUpdated: (el, binding) => updatePageTitle(binding.value || el.innerText),
});
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

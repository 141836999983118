<template>
    <div>
        <div v-if="loading">Loading..</div>
        <div class="pa-12" v-else-if="!isMember">
            <p>This feature requires membership</p>
            <p v-if="isLoggedIn"><a :href="subscriptionLink">Click here to become a member</a></p>
            <p v-else><a href="/login">Click here to login or create an account</a></p>
        </div>
        <template v-else><slot ></slot></template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            userData: undefined,
            loading: true,
        }
    },
    computed: {
        isMember() {
            return this.userData?.isMember;
        },
        isLoggedIn() {
            return this.userData != false;
        },
        subscriptionLink() {
            return this.$apex.getSubscriptionLink();
        }
    },
    methods: {
        async load() {
            this.userData = await this.$apex.getUserData(false);
            this.loading = false;
        }
    },
    mounted() {
        this.load();
    }   
}
</script>